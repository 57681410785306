import * as React from 'react';
import ReduxProvider from 'ReduxProvider';
import { connect } from 'react-redux';
import { setUserData } from 'Client/common/actions/userActions';
import CreateNetworkProjectPrivateLineConfiguration from './CreateNetworkProjectPrivateLineConfiguration__JsBridge.res.js';

const mapDispatchToProps = dispatch => ({
  reduxfunction: {
    setUserData: userData => {
      dispatch(setUserData(userData));
    },
  },
});

const CreateNetworkProjectPrivateLineConfigurationContainer = connect(
  null,
  mapDispatchToProps,
)(CreateNetworkProjectPrivateLineConfiguration);

export default ({ context, ...props }) => (
  <ReduxProvider>
    <CreateNetworkProjectPrivateLineConfigurationContainer
      projectId={props.projectId}
      configurations={props.networkPrivateLineConfigurations}
      options={props.options}
      railsContext={context}
      status={props.status}
      reduxfunction={props.reduxfunction}
      documents={props.documents}
      geographicalRegion={props.geographicalRegion}
    />
  </ReduxProvider>
);
